var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from "react";
import { NO_PIC_PATH, useAppContext } from "../../store";
var defaultProps = {
    width: '100%'
};
var EmployeePhoto = function (_a) {
    var width = _a.width;
    var _b = useAppContext(), currentEmployee = _b.currentEmployee, currentPhoto = _b.currentPhoto, isNewEmployeeMode = _b.isNewEmployeeMode, newEmployee = _b.newEmployee, setCurrentPhoto = _b.setCurrentPhoto;
    useEffect(function () {
        //TODO: Cache the images urls for as long as the presigned url is valid
        if (!isNewEmployeeMode && currentEmployee.photo) {
            var key_1 = currentEmployee.photo;
            fetch("/api/employees/employee-photo", {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify({
                    key: key_1
                })
            }).then(function (resp) { return resp.json(); })
                .then(function (json) {
                setCurrentPhoto({ id: key_1, url: json.data, needsUploading: false });
            }).catch(function (e) {
                console.log(e);
            });
        }
        else {
            setCurrentPhoto({ id: currentPhoto.id, url: currentPhoto.url, needsUploading: currentPhoto.needsUploading });
        }
    }, [currentEmployee]);
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ style: { margin: 'auto', width: width } }, { children: _jsx("img", { src: currentPhoto.url || NO_PIC_PATH, style: { width: width } }, void 0) }), void 0) }, void 0));
};
EmployeePhoto.defaultProps = defaultProps;
export default EmployeePhoto;
