var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import axios from 'axios';
var addNewEmployee = function (employees, newEmployee) { return __spreadArray(__spreadArray([], employees), [
    newEmployee
]); };
var addNewImage = function (images, newImage) { return __spreadArray(__spreadArray([], images), [
    newImage
]); };
var setNewEmployeeName = function (newEmployee, name) {
    return __assign(__assign({}, newEmployee), { name: name });
};
var setNewEmployeePhoto = function (newEmployee, photo) {
    return __assign(__assign({}, newEmployee), { photo: photo });
};
var setUpdatedEmployeePhoto = function (currentEmployee, photo) {
    return __assign(__assign({}, currentEmployee), { photo: photo });
};
var setNewEmployeeEmail = function (newEmployee, email) {
    return __assign(__assign({}, newEmployee), { email: email });
};
var setNewEmployeeLocation = function (newEmployee, location) {
    return __assign(__assign({}, newEmployee), { location: location });
};
var employeeIsAddable = function (e) {
    return e.email !== '' && e.location !== '' && e.name !== '';
};
var getInfoMessage = function (msg, loading) {
    return {
        type: 'info',
        text: msg,
        loading: loading
    };
};
var getErrorMessage = function (msg, loading) {
    return {
        type: 'error',
        text: msg,
        loading: loading
    };
};
var getWarningMessage = function (msg, loading) {
    return {
        type: 'warning',
        text: msg,
        loading: loading
    };
};
export var loadImages = function (callback) {
    fetch("/api/employees/images")
        .then(function (resp) { return resp.json(); })
        .then(function (data) {
        if (data.data && data.data.images) {
            Promise.all(data.data.images.map(function (i) {
                return new Promise(function (resolve, reject) {
                    fetch("/api/employees/employee-photo", {
                        headers: { 'Content-Type': 'application/json' },
                        method: 'POST',
                        body: JSON.stringify({
                            key: i.id
                        })
                    })
                        .then(function (resp) { return resp.json(); })
                        .then(function (json) { return resolve({ id: i.id, url: json.data, needsUploading: false }); })
                        .catch(function (err) { return reject(err); });
                });
            }))
                .then(function (images) { return callback(images); });
        }
    });
};
export var addOrUpdateEmployee = function (isUpdate, photo, file, callback) {
    if (photo.needsUploading) {
        if (file !== null) {
            axios.post("/api/employees/get-upload-url", {
                key: file.name,
                type: file.type
            }).then(function (response) {
                var signedUrl = response.data.data;
                axios.put(signedUrl, file, {
                    headers: { 'Content-Type': file.type },
                    onUploadProgress: function (ev) {
                        var progress = Math.round(ev.loaded / ev.total * 100);
                        var success = progress === 100 ? true : undefined;
                        if (success) {
                            callback(isUpdate, photo.needsUploading);
                        }
                    }
                })
                    .catch(function (err) { console.log(err); });
            });
        }
    }
    else {
        callback(isUpdate, photo.needsUploading);
    }
};
export var EMPTY_EMPLOYEE = { id: '', name: '', email: '', location: '', photo: '' };
var EMPTY_SETTINGS = { database: false, s3: false, photosBucket: '', region: '', showWarnings: false, showAdminTools: false };
export var NO_PICK_ID = 'employee-nopic.png';
export var NO_PIC_PATH = "/photos/" + NO_PICK_ID;
export var NO_PIC = { id: NO_PICK_ID, url: NO_PIC_PATH, needsUploading: false };
var useApp = function (initialEmployees, initialSettings) {
    if (initialEmployees === void 0) { initialEmployees = []; }
    if (initialSettings === void 0) { initialSettings = EMPTY_SETTINGS; }
    var _a = React.useState(initialEmployees), employees = _a[0], setEmployees = _a[1];
    var _b = React.useState(EMPTY_EMPLOYEE), currentEmployee = _b[0], setCurrentEmployee = _b[1];
    var _c = React.useState(EMPTY_EMPLOYEE), newEmployee = _c[0], setNewEmployee = _c[1];
    var _d = React.useState(false), employeesLoading = _d[0], setEmployeesLoading = _d[1];
    var _e = React.useState([]), selectedEmployees = _e[0], setSelectedEmployees = _e[1];
    var _f = React.useState(initialSettings), settings = _f[0], setSettings = _f[1];
    var _g = React.useState(null), globalMessage = _g[0], setMessage = _g[1];
    var _h = React.useState(NO_PIC), currentPhoto = _h[0], setCurrentPhoto = _h[1];
    var _j = React.useState(false), toolsCanOpen = _j[0], setToolsCanOpen = _j[1];
    var _k = React.useState(false), toolsOpen = _k[0], setToolsOpen = _k[1];
    var _l = React.useState([]), images = _l[0], setImages = _l[1];
    var _m = React.useState(false), imagesLoaded = _m[0], setImagesLoaded = _m[1];
    var _o = React.useState(false), canAddEmployee = _o[0], setCanAddEmployee = _o[1];
    var _p = React.useState(null), file = _p[0], setFile = _p[1];
    var _q = React.useState(false), imageUpdateModalVisible = _q[0], setImageUpdateModalVisible = _q[1];
    var _r = React.useState(false), employeeUpdateModalVisible = _r[0], setEmployeeUpdateModalVisible = _r[1];
    var _s = React.useState({}), selectedPhoto = _s[0], setSelectedPhoto = _s[1];
    var _t = React.useState(false), isNewEmployeeMode = _t[0], setIsNewEmployeeMode = _t[1];
    var _u = React.useState(currentPhoto), previousPic = _u[0], setPreviousPic = _u[1];
    var _v = React.useState(''), az = _v[0], setAz = _v[1];
    var _w = React.useState(''), instanceId = _w[0], setInstanceId = _w[1];
    var _x = React.useState([]), configurationItems = _x[0], setConfigurationItems = _x[1];
    var _y = React.useState(0), reloadSettings = _y[0], setReloadSettings = _y[1];
    return {
        settings: settings,
        employees: employees,
        newEmployee: newEmployee,
        currentEmployee: currentEmployee,
        employeesLoading: employeesLoading,
        globalMessage: globalMessage,
        selectedEmployees: selectedEmployees,
        currentPhoto: currentPhoto,
        toolsCanOpen: toolsCanOpen,
        toolsOpen: toolsOpen,
        images: images,
        imagesLoaded: imagesLoaded,
        canAddEmployee: canAddEmployee,
        file: file,
        imageUpdateModalVisible: imageUpdateModalVisible,
        employeeUpdateModalVisible: employeeUpdateModalVisible,
        selectedPhoto: selectedPhoto,
        isNewEmployeeMode: isNewEmployeeMode,
        previousPic: previousPic,
        az: az,
        instanceId: instanceId,
        configurationItems: configurationItems,
        reloadSettings: reloadSettings,
        setReloadSettings: setReloadSettings,
        setConfigurationItems: setConfigurationItems,
        setAz: setAz,
        setInstanceId: setInstanceId,
        setSettings: setSettings,
        setPreviousPic: setPreviousPic,
        setIsNewEmployeeMode: setIsNewEmployeeMode,
        setSelectedPhoto: setSelectedPhoto,
        setEmployeeUpdateModalVisible: setEmployeeUpdateModalVisible,
        setImageUpdateModalVisible: setImageUpdateModalVisible,
        setFile: setFile,
        setCurrentEmployee: setCurrentEmployee,
        setEmployeesLoading: setEmployeesLoading,
        setImagesLoaded: setImagesLoaded,
        setImages: setImages,
        setToolsOpen: setToolsOpen,
        setToolsCanOpen: setToolsCanOpen,
        setCurrentPhoto: setCurrentPhoto,
        setEmployees: setEmployees,
        setNewEmployee: setNewEmployee,
        loadSettings: function (newSettings) { return setSettings(newSettings); },
        showErrorMessage: function (error, loading) { return setMessage(function () { return getErrorMessage(error, loading); }); },
        showInfoMessage: function (info, loading) { return setMessage(function () { return getInfoMessage(info, loading); }); },
        showWarningMessage: function (warning, loading) { return setMessage(function () { return getWarningMessage(warning, loading); }); },
        dismissMessage: function () { return setMessage(null); },
        setSelectedEmployees: function (employees) { return setSelectedEmployees(function (se) {
            setCurrentEmployee(employees[0]);
            return employees;
        }); },
        setEmployeeName: function (value) { return setNewEmployee(function (ne) { setCanAddEmployee(employeeIsAddable(ne)); return setNewEmployeeName(ne, value); }); },
        setEmployeeEmail: function (value) { return setNewEmployee(function (ne) { setCanAddEmployee(employeeIsAddable(ne)); return setNewEmployeeEmail(ne, value); }); },
        setEmployeeLocation: function (value) { return setNewEmployee(function (ne) { setCanAddEmployee(employeeIsAddable(ne)); return setNewEmployeeLocation(ne, value); }); },
        setEmployeePhoto: function (value) { return setNewEmployee(function (ne) { setCanAddEmployee(employeeIsAddable(ne)); return setNewEmployeePhoto(ne, value); }); },
        deleteEmployee: function () { return setEmployees(function (el) {
            setMessage(null);
            setEmployeesLoading(true);
            fetch("/api/employees/delete/" + currentEmployee.id, {
                headers: { 'Content-Type': 'application/json' },
                method: 'DELETE',
            })
                .then(function (resp) { return resp.json(); })
                .then(function (data) {
                if (data.success) {
                    var newList = __spreadArray([], el.filter(function (e) { return e.id !== currentEmployee.id; }));
                    setEmployees(newList);
                    if (newList && newList.length > 0) {
                        setSelectedEmployees([newList[0]]);
                        setCurrentEmployee(newList[0]);
                    }
                }
                else {
                    setMessage(getErrorMessage("Something went wrong when adding employee. " + data.data));
                }
                setMessage(getInfoMessage("Employee '" + currentEmployee.name + "' deleted successfully!"));
                setEmployeesLoading(false);
            }).catch(function (e) {
                console.log(e);
            });
            return el;
        }); },
        addEmployee: function (isUpdate, isNewPhoto) {
            setEmployeesLoading(true);
            var body = JSON.stringify({
                id: newEmployee.id,
                name: newEmployee.name,
                email: newEmployee.email,
                location: newEmployee.location,
                photo: newEmployee.photo
            });
            var url = "/api/employees/" + (isUpdate ? 'update' : 'add');
            fetch(url, {
                headers: { 'Content-Type': 'application/json' },
                method: 'PUT',
                body: body,
            })
                .then(function (resp) { return resp.json(); })
                .then(function (data) {
                if (data.success) {
                    var newList_1 = [];
                    setEmployees(function (el) {
                        if (isUpdate) {
                            var idx = el.findIndex(function (e) { return e.id === currentEmployee.id; });
                            newList_1 = __spreadArray(__spreadArray(__spreadArray([], el.slice(0, idx)), [
                                data.data
                            ]), el.slice(idx + 1));
                        }
                        else {
                            newList_1 = addNewEmployee(el, data.data);
                            setCurrentEmployee(data.data);
                            setNewEmployee(EMPTY_EMPLOYEE);
                        }
                        return el;
                    });
                    setSelectedEmployees([data.data]);
                    setEmployees(newList_1);
                    setMessage(getInfoMessage("Employee '" + data.data.name + "' " + (isUpdate ? 'updated' : 'added') + " successfully!"));
                }
                if (!data.success) {
                    setMessage(getErrorMessage("Something went wrong when adding employee. " + data.data));
                }
                if (isNewPhoto) {
                    setImages([]);
                    loadImages(function (images) {
                        setImages(images);
                        setImagesLoaded(true);
                        setEmployeesLoading(false);
                    });
                }
                else {
                    setEmployeesLoading(false);
                }
            }).catch(function (e) { console.log(e); });
        },
        loadEmployees: function (newEmployees) {
            setEmployees(newEmployees);
            if (newEmployees && newEmployees.length > 0) {
                setCurrentEmployee(newEmployees[0]);
                setSelectedEmployees([newEmployees[0]]);
                setToolsCanOpen(true);
                setToolsOpen(true);
            }
        }
    };
};
var AppContext = React.createContext(null);
export var useAppContext = function () { return React.useContext(AppContext); };
export var AppProvider = function (_a) {
    var children = _a.children;
    return (_jsx(AppContext.Provider, __assign({ value: useApp() }, { children: children }), void 0));
};
