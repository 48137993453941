var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Spinner, SpaceBetween, Container, Flashbar, Box } from "@awsui/components-react";
import * as tokens from '@awsui/design-tokens';
import { useAppContext } from "../../store";
var AppHeader = function (_a) {
    var title = _a.title;
    var _b = useAppContext(), settings = _b.settings, globalMessage = _b.globalMessage, dismissMessage = _b.dismissMessage;
    return (_jsxs("div", __assign({ style: { background: tokens.colorBackgroundHomeHeader } }, { children: [_jsx(Box, __assign({ padding: { vertical: 'xxl', horizontal: 's' } }, { children: _jsxs("div", __assign({ className: "custom-home-text-inverted" }, { children: [_jsxs(Box, __assign({ variant: "h1", fontWeight: "bold", padding: "m", fontSize: "display-l", color: "inherit" }, { children: ["Employee Directory - ", title] }), void 0),
                        _jsx(Box, __assign({ variant: "p", fontWeight: "light" }, { children: _jsx("span", { className: "custom-home-text-secondary" }, void 0) }), void 0)] }), void 0) }), void 0),
            (!settings.database || !settings.s3) && settings.showWarnings &&
                _jsx(Flashbar, { items: [
                        {
                            header: _jsxs("div", { children: ["Application Status:", _jsxs("ul", { children: [!settings.database && _jsx("li", { children: "DynamoDB: 'Employees' table not found." }, void 0),
                                            !settings.s3 && _jsx("li", { children: "S3: Employee images bucket not found." }, void 0)] }, void 0)] }, void 0),
                            type: "warning",
                            dismissible: false
                        }
                    ] }, void 0),
            globalMessage &&
                _jsx(Container, { children: _jsx(SpaceBetween, __assign({ direction: "vertical", size: "l" }, { children: _jsx(Flashbar, { items: [
                                {
                                    header: _jsxs(_Fragment, { children: [globalMessage.loading && _jsx(Spinner, { size: "normal" }, void 0), " ", globalMessage.text] }, void 0),
                                    type: globalMessage.type,
                                    dismissible: true,
                                    onDismiss: function () { dismissMessage(); }
                                }
                            ] }, void 0) }), void 0) }, void 0)] }), void 0));
};
export default AppHeader;
