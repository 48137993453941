var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, Box, Button, Container, SpaceBetween, FormField, Input } from "@awsui/components-react";
import { addOrUpdateEmployee, useAppContext } from "../../store";
import AddPhotoTabs from "../add-photo-tabs";
import EmployeePhoto from "../employee-photo";
var EmployeeDetail = function () {
    var _a = useAppContext(), settings = _a.settings, imageUpdateModalVisible = _a.imageUpdateModalVisible, currentPhoto = _a.currentPhoto, currentEmployee = _a.currentEmployee, file = _a.file, previousPic = _a.previousPic, setIsNewEmployeeMode = _a.setIsNewEmployeeMode, setSelectedPhoto = _a.setSelectedPhoto, setNewEmployee = _a.setNewEmployee, setPreviousPic = _a.setPreviousPic, setImageUpdateModalVisible = _a.setImageUpdateModalVisible, setCurrentPhoto = _a.setCurrentPhoto, addEmployee = _a.addEmployee;
    return (_jsxs(Container, { children: [_jsx(EmployeePhoto, { width: "70%" }, void 0),
            _jsx(FormField, __assign({ label: "Photo", secondaryControl: _jsx(Button, __assign({ variant: "primary", onClick: function () {
                        setPreviousPic(currentPhoto);
                        setIsNewEmployeeMode(false);
                        setNewEmployee(currentEmployee);
                        setSelectedPhoto({ label: currentEmployee.photo, value: '' });
                        setImageUpdateModalVisible(true);
                    }, disabled: !settings.s3 }, { children: "Update Photo" }), void 0) }, { children: _jsx(Input, { name: "photo", value: currentPhoto.id || 'N/A', disabled: true }, void 0) }), void 0),
            _jsxs(Modal, __assign({ onDismiss: function () {
                    setCurrentPhoto(previousPic);
                    setImageUpdateModalVisible(false);
                }, visible: imageUpdateModalVisible, closeAriaLabel: "Close", size: "medium", footer: _jsx(Box, __assign({ float: "right" }, { children: _jsxs(SpaceBetween, __assign({ direction: "horizontal", size: "xs" }, { children: [_jsx(Button, __assign({ variant: "link", onClick: function () {
                                    setCurrentPhoto(previousPic);
                                    setImageUpdateModalVisible(false);
                                } }, { children: "Cancel" }), void 0),
                            _jsx(Button, __assign({ variant: "primary", onClick: function () {
                                    addOrUpdateEmployee(true, currentPhoto, file, addEmployee);
                                    setImageUpdateModalVisible(false);
                                } }, { children: "Update" }), void 0)] }), void 0) }), void 0), header: "Update Employee Photo" }, { children: [settings.s3 && _jsx(AddPhotoTabs, {}, void 0),
                    _jsx("br", {}, void 0),
                    _jsx("div", __assign({ style: { margin: 'auto', width: '50%' } }, { children: _jsx(EmployeePhoto, {}, void 0) }), void 0)] }), void 0)] }, void 0));
};
export default EmployeeDetail;
