import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import SideNavigation from '@awsui/components-react/side-navigation';
var HOME = { text: 'Employee Management', href: '#/' };
var items = [
    {
        type: 'section',
        text: 'Employees',
        items: [
            { type: 'link', text: 'Management', href: '#/' },
            { type: 'link', text: 'Images', href: '#/images' },
        ],
    },
    {
        type: 'section',
        text: 'Administration',
        items: [
            { type: 'link', text: 'Configuration', href: '#/configuration' },
        ],
    },
];
var LeftNavigationLocal = function (_a) {
    var location = _a.location, history = _a.history;
    // If the provided link is empty, do not redirect pages
    var onFollowHandler = useCallback(function (event) {
        event.preventDefault();
        if (event.detail.href) {
            history.push(event.detail.href.substring(1));
        }
    }, []);
    return (_jsx(SideNavigation, { header: HOME, items: items, activeHref: "#" + location.pathname, onFollow: onFollowHandler }, void 0));
};
export var LeftNavigation = withRouter(LeftNavigationLocal);
