var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect, useCallback } from 'react';
import { Checkbox, ColumnLayout, ProgressBar, Input, ButtonDropdown, FormField, Icon, Table, Box, Container, SpaceBetween, Header, Button } from "@awsui/components-react";
import { useAppContext } from "../../store";
export default function ShowConfiguration() {
    var _a = useAppContext(), settings = _a.settings, configurationItems = _a.configurationItems, setReloadSettings = _a.setReloadSettings, showInfoMessage = _a.showInfoMessage, showWarningMessage = _a.showWarningMessage;
    var _b = useState(false), stressInProgress = _b[0], setStressInProgress = _b[1];
    var _c = useState(settings.photosBucket), newBucketName = _c[0], setNewBucketName = _c[1];
    var prevBucketName = useState(settings.photosBucket)[0];
    var _d = useState(false), isBucketUpdate = _d[0], setIsBucketUpdate = _d[1];
    var _e = useState(0), cpuUsage = _e[0], setCpuUsage = _e[1];
    var _f = useState(0), setIntervalId = _f[1];
    var _g = useState(true), showCpuUsage = _g[0], setShowCpuUsage = _g[1];
    var startInterval = useCallback(function () {
        var interval = function () { return setInterval(function () {
            fetch("/api/settings/cpu", {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST'
            })
                .then(function (resp) { return resp.json(); })
                .then(function (json) {
                if (json.success) {
                    setCpuUsage(json.data.stdout);
                }
            });
        }, 1000); }; //every second
        var id = interval();
        setIntervalId(id);
    }, []);
    useEffect(function () {
        startInterval();
    }, []);
    return (_jsxs(Container, { children: [_jsx(SpaceBetween, __assign({ direction: "vertical", size: "l" }, { children: _jsx(Table, { columnDefinitions: [
                        {
                            id: "setting",
                            header: "Setting",
                            cell: function (item) { return item.name; }
                        },
                        {
                            id: "value",
                            header: "Value",
                            cell: function (item) {
                                if (typeof (item.value) === typeof (true)) {
                                    return (_jsx(Icon, { variant: (item.value ? "success" : "error"), name: (item.value ? "status-positive" : "status-negative") }, void 0));
                                }
                                else {
                                    if (item.name === 'S3 Bucket') {
                                        return (_jsx(FormField, __assign({ secondaryControl: _jsxs(_Fragment, { children: [_jsx(Button, __assign({ variant: "primary", onClick: function () {
                                                            setIsBucketUpdate(function (bu) {
                                                                if (bu) { //isBucketUpdate
                                                                    fetch("/api/settings/set-bucket", {
                                                                        headers: { 'Content-Type': 'application/json' },
                                                                        method: 'POST',
                                                                        body: JSON.stringify({
                                                                            bucket: newBucketName
                                                                        })
                                                                    })
                                                                        .then(function (resp) { return resp.json(); })
                                                                        .then(function (json) {
                                                                        setNewBucketName(json.data.bucket);
                                                                        setReloadSettings(function (rs) { return rs + 1; });
                                                                    });
                                                                }
                                                                return !bu;
                                                            });
                                                        } }, { children: isBucketUpdate ? 'Save' : 'Change' }), void 0),
                                                    isBucketUpdate && _jsx(Button, __assign({ variant: "link", onClick: function () {
                                                            setIsBucketUpdate(false);
                                                            setNewBucketName(prevBucketName);
                                                        } }, { children: "Cancel" }), void 0)] }, void 0) }, { children: _jsx(Input, { name: "photo", value: newBucketName, disabled: !isBucketUpdate, onChange: function (evt) { return setNewBucketName(evt.detail.value); } }, void 0) }), void 0));
                                    }
                                    return item.value || "-";
                                }
                            }
                        }
                    ], items: configurationItems, loadingText: "Loading resources", empty: _jsxs(Box, __assign({ textAlign: "center", color: "inherit" }, { children: [_jsx("b", { children: "No settings" }, void 0),
                            _jsx(Box, __assign({ padding: { bottom: "s" }, variant: "p", color: "inherit" }, { children: "No settings available." }), void 0)] }), void 0), header: _jsx(Header, { children: " Configuration Settings " }, void 0) }, void 0) }), void 0),
            settings.showAdminTools && _jsx(SpaceBetween, __assign({ direction: "vertical", size: "l" }, { children: _jsxs(Container, { children: [_jsx(Header, { children: "Admin Tools" }, void 0),
                        _jsxs(ColumnLayout, __assign({ columns: 2 }, { children: [_jsx(FormField, __assign({ description: "This tool allows you to see the current cpu usage of the server.", label: "CPU Status" }, { children: _jsx(Checkbox, __assign({ onChange: function (_a) {
                                            var detail = _a.detail;
                                            if (detail.checked) {
                                                startInterval();
                                            }
                                            else {
                                                setIntervalId(function (it) {
                                                    clearInterval(it);
                                                    return 0;
                                                });
                                            }
                                            setShowCpuUsage(detail.checked);
                                        }, checked: showCpuUsage }, { children: "Show" }), void 0) }), void 0),
                                showCpuUsage && _jsx(ProgressBar, { value: cpuUsage, label: "CPU Usage" }, void 0)] }), void 0),
                        _jsx("br", {}, void 0),
                        _jsxs(FormField, __assign({ description: "This tool allows you to stress the application to test load balancing.", label: "Stress Testing" }, { children: [_jsx(ButtonDropdown, __assign({ variant: "primary", disabled: stressInProgress || cpuUsage > 60, items: [
                                        { text: "10 minutes", id: "600", disabled: false }
                                    ], onItemClick: function (event) {
                                        setStressInProgress(true);
                                        showWarningMessage("Stressing...", true);
                                        fetch("/api/settings/stress", {
                                            headers: { 'Content-Type': 'application/json' },
                                            method: 'POST',
                                            body: JSON.stringify({
                                                seconds: event.detail.id
                                            })
                                        })
                                            .then(function (resp) { return resp.json(); })
                                            .then(function (json) {
                                            if (json.success) {
                                                showInfoMessage("Stress test finished.");
                                                setStressInProgress(false);
                                            }
                                        });
                                    } }, { children: "Stress Application Server For:" }), void 0),
                                _jsx("br", {}, void 0)] }), void 0)] }, void 0) }), void 0)] }, void 0));
}
