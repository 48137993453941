var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var appLayoutNavigationLabels = {
    navigationClose: 'Close navigation',
    navigationToggle: 'Toggle navigation'
};
var appLayoutToolsLabels = {
    tools: 'Tool bar',
    toolsClose: 'Close tool bar',
    toolsToggle: 'Open tool bar'
};
var appLayoutLabels = __assign(__assign({}, appLayoutNavigationLabels), appLayoutToolsLabels);
export { appLayoutNavigationLabels, appLayoutToolsLabels, appLayoutLabels };
