var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Cards, Box, Container, SpaceBetween, Header } from "@awsui/components-react";
import { useAppContext } from "../../store";
export default function ListEmployeeImages() {
    var _a = useAppContext(), images = _a.images, settings = _a.settings, imagesLoaded = _a.imagesLoaded;
    return (_jsx(Container, { children: _jsx(SpaceBetween, __assign({ direction: "vertical", size: "l" }, { children: settings.s3 && _jsx(Cards, { trackBy: "id", cardDefinition: {
                    header: function (e) { return e.id.substr(0, e.id.indexOf('.')); },
                    sections: [{
                            id: "preview",
                            header: "Preview",
                            content: function (e) { return _jsx("img", { src: e.url, style: { width: '100%' } }, void 0); },
                            width: 50
                        }]
                }, items: images, loading: settings.s3 && !imagesLoaded, loadingText: "Loading images", empty: _jsxs(Box, __assign({ textAlign: "center", color: "inherit" }, { children: [_jsx("b", { children: "No images available." }, void 0),
                        _jsx(Box, __assign({ padding: { bottom: "s" }, variant: "p", color: "inherit" }, { children: "-" }), void 0)] }), void 0), header: _jsx(Header, { children: "S3 - Employee Images" }, void 0) }, void 0) }), void 0) }, void 0));
}
