var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Input, FormField, Container } from "@awsui/components-react";
import { useAppContext } from "../../store";
import AddPhotoTabs from '../add-photo-tabs';
export default function AddEmployeeForm() {
    var _a = useAppContext(), settings = _a.settings, newEmployee = _a.newEmployee, setEmployeeName = _a.setEmployeeName, setEmployeeEmail = _a.setEmployeeEmail, setEmployeeLocation = _a.setEmployeeLocation;
    return (_jsx(_Fragment, { children: settings.database && _jsx(Container, { children: _jsxs("div", __assign({ className: "awsui-util-mt-m" }, { children: [_jsx(FormField, __assign({ label: "Name", description: "Employee's full name." }, { children: _jsx(Input, { name: "name", value: newEmployee.name, onChange: function (evt) { return setEmployeeName(evt.detail.value); } }, void 0) }), void 0),
                    _jsx(FormField, __assign({ label: "Location", description: "Employee's location (eg: New York)." }, { children: _jsx(Input, { name: "location", value: newEmployee.location, onChange: function (evt) { return setEmployeeLocation(evt.detail.value); } }, void 0) }), void 0),
                    _jsx(FormField, __assign({ label: "Email", description: "Employee email." }, { children: _jsx(Input, { name: "email", value: newEmployee.email, onChange: function (evt) { return setEmployeeEmail(evt.detail.value); } }, void 0) }), void 0),
                    _jsx("br", {}, void 0),
                    settings.s3 && _jsx(AddPhotoTabs, {}, void 0)] }), void 0) }, void 0) }, void 0));
}
