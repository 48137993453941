var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Modal, ButtonDropdown, Icon, TextFilter, Table, Box, Container, SpaceBetween, Header } from "@awsui/components-react";
import { EMPTY_EMPLOYEE, useAppContext, addOrUpdateEmployee, NO_PIC_PATH } from "../../store";
import AddEmployeeForm from '../add-employee-form';
import EmployeePhoto from '../employee-photo';
var ListEmployees = function () {
    var _a = useAppContext(), settings = _a.settings, file = _a.file, currentPhoto = _a.currentPhoto, employees = _a.employees, selectedEmployees = _a.selectedEmployees, employeesLoading = _a.employeesLoading, employeeUpdateModalVisible = _a.employeeUpdateModalVisible, currentEmployee = _a.currentEmployee, isNewEmployeeMode = _a.isNewEmployeeMode, canAddEmployee = _a.canAddEmployee, previousPic = _a.previousPic, addEmployee = _a.addEmployee, setSelectedPhoto = _a.setSelectedPhoto, setNewEmployee = _a.setNewEmployee, setEmployeeUpdateModalVisible = _a.setEmployeeUpdateModalVisible, setSelectedEmployees = _a.setSelectedEmployees, deleteEmployee = _a.deleteEmployee, setCurrentPhoto = _a.setCurrentPhoto, setPreviousPic = _a.setPreviousPic, setIsNewEmployeeMode = _a.setIsNewEmployeeMode;
    return (_jsxs(Container, { children: [_jsx(SpaceBetween, __assign({ direction: "vertical", size: "l" }, { children: settings.database && _jsx(Table, { trackBy: "id", columnDefinitions: [
                        {
                            id: "id",
                            header: "Employee ID",
                            cell: function (e) { return e.id; },
                        },
                        {
                            id: "name",
                            header: "Employee Name",
                            cell: function (e) { return e.name; },
                        },
                        {
                            id: "location",
                            header: "Location ",
                            cell: function (e) { return e.location; },
                        },
                        {
                            id: "email",
                            header: "Email ",
                            cell: function (e) { return e.email; },
                            //sortingField: "email"
                        },
                        {
                            id: "photo",
                            header: "Photo Available",
                            cell: function (e) { return _jsx(Icon, { variant: (e.photo ? "success" : "error"), name: (e.photo ? "status-positive" : "status-negative") }, void 0); }
                        },
                    ], items: employees, selectedItems: selectedEmployees, loading: employeesLoading, loadingText: "Loading employees", selectionType: "single", visibleColumns: [
                        "name",
                        "location",
                        "email",
                        "photo"
                    ], empty: _jsxs(Box, __assign({ textAlign: "center", color: "inherit" }, { children: [_jsx("b", { children: "No employees available." }, void 0),
                            _jsx(Box, __assign({ padding: { bottom: "s" }, variant: "p", color: "inherit" }, { children: "-" }), void 0)] }), void 0), filter: _jsx(TextFilter, { filteringPlaceholder: "Find employees", filteringText: "" }, void 0), header: _jsx(Header, __assign({ actions: _jsx(ButtonDropdown, __assign({ variant: "primary", items: [
                                { text: "New Employee", id: "ne", disabled: false },
                                { text: "Delete", id: "rm", disabled: false },
                                { text: "Edit", id: "ed", disabled: false }
                            ], onItemClick: function (event) {
                                switch (event.detail.id) {
                                    case "rm":
                                        setSelectedPhoto({ label: currentEmployee.photo, value: '' });
                                        deleteEmployee();
                                        break;
                                    case "ne":
                                        setPreviousPic(currentPhoto);
                                        setCurrentPhoto({ id: '', needsUploading: false });
                                        setIsNewEmployeeMode(true);
                                        setNewEmployee(EMPTY_EMPLOYEE);
                                        setSelectedPhoto({ label: '-', value: NO_PIC_PATH });
                                        setEmployeeUpdateModalVisible(true);
                                        break;
                                    case "ed":
                                        setPreviousPic(currentPhoto);
                                        setIsNewEmployeeMode(false);
                                        setNewEmployee(currentEmployee);
                                        setSelectedPhoto({ label: currentEmployee.photo, value: '' });
                                        setEmployeeUpdateModalVisible(true);
                                        break;
                                    default:
                                        break;
                                }
                            } }, { children: "Actions" }), void 0) }, { children: "Employees" }), void 0), onSelectionChange: function (_a) {
                        var detail = _a.detail;
                        return setSelectedEmployees(detail.selectedItems);
                    } }, void 0) }), void 0),
            _jsxs(Modal, __assign({ visible: employeeUpdateModalVisible, closeAriaLabel: "Close", onDismiss: function () {
                    setNewEmployee(EMPTY_EMPLOYEE);
                    setEmployeeUpdateModalVisible(false);
                    setCurrentPhoto(previousPic);
                }, size: "medium", footer: _jsx(Box, __assign({ float: "right" }, { children: _jsxs(SpaceBetween, __assign({ direction: "horizontal", size: "xs" }, { children: [_jsx(Button, __assign({ variant: "link", onClick: function () {
                                    setNewEmployee(EMPTY_EMPLOYEE);
                                    setEmployeeUpdateModalVisible(false);
                                    setCurrentPhoto(previousPic);
                                } }, { children: "Cancel" }), void 0),
                            _jsx(Button, __assign({ variant: "primary", onClick: function () {
                                    addOrUpdateEmployee(!isNewEmployeeMode, currentPhoto, file, addEmployee);
                                    setIsNewEmployeeMode(false);
                                    setEmployeeUpdateModalVisible(false);
                                }, disabled: !canAddEmployee }, { children: (isNewEmployeeMode ? 'Add' : 'Update') }), void 0)] }), void 0) }), void 0), header: "Employee details" }, { children: [_jsx(AddEmployeeForm, {}, void 0),
                    _jsx("br", {}, void 0),
                    _jsx("div", __assign({ style: { margin: 'auto', width: '50%' } }, { children: _jsx(EmployeePhoto, {}, void 0) }), void 0)] }), void 0)] }, void 0));
};
export default ListEmployees;
