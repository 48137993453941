import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Route, HashRouter } from 'react-router-dom';
import { AppProvider } from '../store';
import HomePageContent from './pages/home';
import ListEmployeesImagesPageContent from './pages/list-employees-images';
import ConfigurationPageContent from './pages/configuration';
// Class App is the "output" generated on every build,
// it is what you will see on the webpage.
export default function App() {
    return (_jsx(AppProvider, { children: _jsx("div", { children: _jsxs(HashRouter, { children: [_jsx(Route, { exact: true, path: "/", component: HomePageContent }, void 0),
                    _jsx(Route, { exact: true, path: "/images", component: ListEmployeesImagesPageContent }, void 0),
                    _jsx(Route, { exact: true, path: "/configuration", component: ConfigurationPageContent }, void 0)] }, void 0) }, void 0) }, void 0));
}
