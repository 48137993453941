var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createRef } from 'react';
import { Select, Tabs, Input, FormField, Button } from "@awsui/components-react";
import { NO_PIC_PATH, useAppContext } from "../../store";
var AddPhotoTabs = function () {
    var _a = useAppContext(), newEmployee = _a.newEmployee, images = _a.images, selectedPhoto = _a.selectedPhoto, setFile = _a.setFile, setEmployeePhoto = _a.setEmployeePhoto, setCurrentPhoto = _a.setCurrentPhoto, setToolsCanOpen = _a.setToolsCanOpen, setToolsOpen = _a.setToolsOpen, setSelectedPhoto = _a.setSelectedPhoto;
    var hiddenFileInput = createRef();
    return (_jsx(Tabs, { tabs: [
            {
                label: "Select existing photo",
                id: "first",
                content: (_jsx(FormField, __assign({ label: "Photo", description: "Available employee photos:" }, { children: _jsx(Select, { selectedOption: selectedPhoto || { label: '-', value: NO_PIC_PATH }, onChange: function (_a) {
                            var detail = _a.detail;
                            setSelectedPhoto(detail.selectedOption);
                            setEmployeePhoto(detail.selectedOption.label || NO_PIC_PATH);
                            setCurrentPhoto({ id: detail.selectedOption.label || "", url: detail.selectedOption.value, needsUploading: false });
                            setToolsCanOpen(true);
                            setToolsOpen(true);
                        }, options: (__spreadArray([{ label: '-', value: NO_PIC_PATH }], images.map(function (i) { return { label: i.id, value: i.url }; }))), selectedAriaLabel: "Selected" }, void 0) }), void 0))
            },
            {
                label: "Upload new photo",
                id: "second",
                content: (_jsxs(FormField, __assign({ label: "Photo", description: "Upload a new employee photo.", secondaryControl: _jsx(Button, __assign({ variant: "primary", onClick: function () {
                            var _a;
                            (_a = hiddenFileInput.current) === null || _a === void 0 ? void 0 : _a.click();
                        }, iconName: 'file-open' }, { children: "Browse..." }), void 0) }, { children: [_jsx("input", { ref: hiddenFileInput, id: "chooseFileInput", type: "file", hidden: true, multiple: false, accept: ".png, .svg, .jpeg, jpg", onChange: function (e) {
                                if (e.target.files && e.target.files.length > 0) {
                                    setFile(e.target.files[0]);
                                    var url = URL.createObjectURL(e.target.files[0]);
                                    setCurrentPhoto({ id: e.target.files[0].name, url: url, needsUploading: true });
                                    setToolsCanOpen(true);
                                    setEmployeePhoto(e.target.files[0].name);
                                    setToolsOpen(true);
                                }
                                ;
                            } }, void 0),
                        _jsx(Input, { name: "photo", value: newEmployee.photo, disabled: true }, void 0)] }), void 0))
            }
        ], variant: "container" }, void 0));
};
export default AddPhotoTabs;
