var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import { Box, Spinner, AppLayout } from '@awsui/components-react';
import { LeftNavigation } from '../../left-navigation';
import { appLayoutNavigationLabels } from '../../../common/labels';
import '../../../common/styles/styles.scss';
import EmployeeDetails from '../../employee-detail';
import { loadImages, useAppContext } from '../../../store';
import axios from 'axios';
var defaultProps = {
    content: _jsx(_Fragment, {}, void 0),
    forceToolsClosed: false
};
var AppLayoutComponent = function (_a) {
    var content = _a.content, forceToolsClosed = _a.forceToolsClosed;
    var _b = useState(true), navigationOpen = _b[0], setNavigationOpen = _b[1];
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useAppContext(), settings = _d.settings, reloadSettings = _d.reloadSettings, toolsOpen = _d.toolsOpen, toolsCanOpen = _d.toolsCanOpen, setConfigurationItems = _d.setConfigurationItems, setAz = _d.setAz, setInstanceId = _d.setInstanceId, setImages = _d.setImages, setImagesLoaded = _d.setImagesLoaded, setEmployees = _d.setEmployees, setEmployeesLoading = _d.setEmployeesLoading, loadEmployees = _d.loadEmployees, setToolsOpen = _d.setToolsOpen, loadSettings = _d.loadSettings;
    //Load Settings
    useEffect(function () {
        // console.log('useEffect:load-settings');        
        setLoading(true);
        axios.get("/api/settings")
            .then(function (response) {
            loadSettings(response.data);
            setLoading(false);
        });
    }, [reloadSettings]);
    //Load Images
    useEffect(function () {
        if (settings.s3) {
            // console.log('useEffect:load-images');       
            setImagesLoaded(false);
            setImages([]);
            try {
                loadImages(function (images) {
                    setImages(images);
                    setImagesLoaded(true);
                });
            }
            catch (e) {
            }
        }
    }, [settings]);
    //Load Employees
    useEffect(function () {
        if (settings.database) {
            // console.log('useEffect:load-employees');        
            setEmployeesLoading(true);
            setEmployees([]);
            axios.get("/api/employees/")
                .then(function (response) {
                loadEmployees(response.data.data);
                setEmployeesLoading(false);
            });
        }
    }, [settings]);
    useEffect(function () {
        // console.log('useEffect:info');      
        axios.get("/api/settings/info")
            .then(function (response) {
            var az = response.data.data.availabilityZone;
            var instanceId = response.data.data.instanceId;
            setAz(az);
            setInstanceId(instanceId);
            setConfigurationItems([
                {
                    name: "Dynamo DB Enabled",
                    value: settings.database,
                },
                {
                    name: "S3 Access Enabled",
                    value: settings.s3,
                },
                {
                    name: "S3 Bucket",
                    value: settings.photosBucket,
                },
                {
                    name: "Region",
                    value: settings.region,
                },
                {
                    name: "Availability Zone",
                    value: az,
                },
                {
                    name: "EC2 Instance Id",
                    value: instanceId,
                }
            ]);
        }).catch(function (e) { return console.log(e); });
    }, [settings]);
    return (_jsxs(_Fragment, { children: [loading && _jsx(Box, __assign({ className: "loading-container", textAlign: "center" }, { children: _jsx(Box, __assign({ className: "loading-child" }, { children: _jsx(Spinner, { size: "big", className: "loading-child" }, void 0) }), void 0) }), void 0),
            !loading && _jsx(AppLayout, { navigation: _jsx(LeftNavigation, {}, void 0), content: content, contentType: "default", disableContentPaddings: true, navigationOpen: navigationOpen, navigationWidth: 220, onNavigationChange: function (event) { return setNavigationOpen(event.detail.open); }, toolsOpen: !forceToolsClosed && toolsOpen, onToolsChange: function (e) { if (toolsCanOpen) {
                    setToolsOpen(e.detail.open);
                } }, toolsWidth: 300, tools: !forceToolsClosed && toolsCanOpen ? _jsx(EmployeeDetails, {}, void 0) : null, ariaLabels: appLayoutNavigationLabels }, void 0)] }, void 0));
};
AppLayoutComponent.defaultProps = defaultProps;
export default AppLayoutComponent;
